var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c("div", { staticClass: "box-content" }, [
      _c(
        "div",
        { staticClass: "form-group text-center" },
        [
          _c("h4", [_vm._v(_vm._s(_vm.$t("ACTIVATED_SERVER")))]),
          _c("p", [
            _vm._v(
              "\n        \n       " +
                _vm._s(_vm.$t("ACTIVATED_SERVER2")) +
                "\n       "
            ),
          ]),
          _c(
            "router-link",
            {
              staticClass: "btn btn-secondary",
              attrs: { to: { name: "Login" } },
            },
            [_vm._v(_vm._s(_vm.$t("BACK")))]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }