<template>  
  <section>
      
    <div class="box-content">

        <div class="form-group text-center">

         <h4>{{
            $t("ACTIVATED_SERVER")
          }}</h4>

         <p>
          
         {{
            $t("ACTIVATED_SERVER2")
          }}
         </p>

          <router-link class="btn btn-secondary" :to="{ name: 'Login' }">{{
        $t("BACK")
      }}</router-link>
        </div>

    </div>

  </section>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
  },
  mounted: function() {
  }
};
</script>
<style></style>
